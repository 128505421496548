input[type=range].range-slider {
  -webkit-appearance: none;
  display: block;
  width: 100%;
  height: calc(1.5em + 0.75rem + 2px);
  padding: 0.375rem 0;
  line-height: 1.5;
  font: 1rem/1 arial, sans-serif;
  color: #ced4da;
  background-clip: padding-box;
  background: transparent;
  border: none;
  outline: none;
  box-shadow: none;
  position: relative;
}
input[type=range].range-slider::-moz-focus-outer {
  outline: none;
  border: none;
}
input[type=range].range-slider::-moz-focusring {
  outline: none;
}
input[type=range].range-slider.range-slider--sm {
  height: calc(1.5em + .5rem + 2px);
  padding: 0.25rem 0;
  font-size: 0.875rem;
}
input[type=range].range-slider.range-slider--lg {
  height: calc(1.5em + 1rem + 2px);
  padding: 0.5rem 0;
  font-size: 1.25rem;
}
input[type=range].range-slider::-webkit-slider-runnable-track {
  box-sizing: border-box;
  border: none;
  height: 4px;
  background: #ced4da;
  border-radius: 2px;
}
input[type=range].range-slider::-moz-range-track {
  box-sizing: border-box;
  border: none;
  height: 4px;
  background: #ced4da;
  border-radius: 2px;
}
input[type=range].range-slider::-ms-track {
  box-sizing: border-box;
  border: none;
  height: 4px;
  background: #ced4da;
  border-radius: 2px;
}
input[type=range].range-slider::-ms-fill-lower {
  box-sizing: border-box;
  border: none;
  height: 4px;
  background: #ced4da;
  border-radius: 2px;
}
input[type=range].range-slider::-ms-track {
  color: transparent;
}
input[type=range].range-slider.range-slider--lg::-webkit-slider-runnable-track {
  height: 6px;
  border-radius: 3px;
}
input[type=range].range-slider.range-slider--lg::-moz-range-track {
  height: 6px;
  border-radius: 3px;
}
input[type=range].range-slider.range-slider--lg::-ms-track {
  height: 6px;
  border-radius: 3px;
}
input[type=range].range-slider.range-slider--lg::-ms-fill-lower {
  height: 6px;
  border-radius: 3px;
}
input[type=range].range-slider.disabled::-webkit-slider-runnable-track {
  background: #e9e9e9;
  cursor: not-allowed;
}
input[type=range].range-slider.disabled::-moz-range-track {
  background: #e9e9e9;
  cursor: not-allowed;
}
input[type=range].range-slider.disabled::-ms-track {
  background: #e9e9e9;
  cursor: not-allowed;
}
input[type=range].range-slider.range-slider--primary::-webkit-slider-thumb {
  background: #007bff;
}
input[type=range].range-slider.range-slider--primary::-moz-range-thumb {
  background: #007bff;
}
input[type=range].range-slider.range-slider--primary::-ms-thumb {
  background: #007bff;
}
input[type=range].range-slider.range-slider--primary:not(.disabled):focus::-webkit-slider-thumb, input[type=range].range-slider.range-slider--primary:not(.disabled):active::-webkit-slider-thumb {
  box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
}
input[type=range].range-slider.range-slider--primary:not(.disabled):focus::-moz-range-thumb, input[type=range].range-slider.range-slider--primary:not(.disabled):active::-moz-range-thumb {
  box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
}
input[type=range].range-slider.range-slider--primary:not(.disabled):focus::-ms-thumb, input[type=range].range-slider.range-slider--primary:not(.disabled):active::-ms-thumb {
  box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
}
input[type=range].range-slider.range-slider--secondary::-webkit-slider-thumb {
  background: #6c757d;
}
input[type=range].range-slider.range-slider--secondary::-moz-range-thumb {
  background: #6c757d;
}
input[type=range].range-slider.range-slider--secondary::-ms-thumb {
  background: #6c757d;
}
input[type=range].range-slider.range-slider--secondary:not(.disabled):focus::-webkit-slider-thumb, input[type=range].range-slider.range-slider--secondary:not(.disabled):active::-webkit-slider-thumb {
  box-shadow: 0 0 0 0.2rem rgba(108, 117, 125, 0.25);
}
input[type=range].range-slider.range-slider--secondary:not(.disabled):focus::-moz-range-thumb, input[type=range].range-slider.range-slider--secondary:not(.disabled):active::-moz-range-thumb {
  box-shadow: 0 0 0 0.2rem rgba(108, 117, 125, 0.25);
}
input[type=range].range-slider.range-slider--secondary:not(.disabled):focus::-ms-thumb, input[type=range].range-slider.range-slider--secondary:not(.disabled):active::-ms-thumb {
  box-shadow: 0 0 0 0.2rem rgba(108, 117, 125, 0.25);
}
input[type=range].range-slider.range-slider--success::-webkit-slider-thumb {
  background: #28a745;
}
input[type=range].range-slider.range-slider--success::-moz-range-thumb {
  background: #28a745;
}
input[type=range].range-slider.range-slider--success::-ms-thumb {
  background: #28a745;
}
input[type=range].range-slider.range-slider--success:not(.disabled):focus::-webkit-slider-thumb, input[type=range].range-slider.range-slider--success:not(.disabled):active::-webkit-slider-thumb {
  box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.25);
}
input[type=range].range-slider.range-slider--success:not(.disabled):focus::-moz-range-thumb, input[type=range].range-slider.range-slider--success:not(.disabled):active::-moz-range-thumb {
  box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.25);
}
input[type=range].range-slider.range-slider--success:not(.disabled):focus::-ms-thumb, input[type=range].range-slider.range-slider--success:not(.disabled):active::-ms-thumb {
  box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.25);
}
input[type=range].range-slider.range-slider--danger::-webkit-slider-thumb {
  background: var(--danger);
}
input[type=range].range-slider.range-slider--danger::-moz-range-thumb {
  background: var(--danger);
}
input[type=range].range-slider.range-slider--danger::-ms-thumb {
  background: var(--danger);
}
input[type=range].range-slider.range-slider--danger:not(.disabled):focus::-webkit-slider-thumb, input[type=range].range-slider.range-slider--danger:not(.disabled):active::-webkit-slider-thumb {
  box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.25);
}
input[type=range].range-slider.range-slider--danger:not(.disabled):focus::-moz-range-thumb, input[type=range].range-slider.range-slider--danger:not(.disabled):active::-moz-range-thumb {
  box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.25);
}
input[type=range].range-slider.range-slider--danger:not(.disabled):focus::-ms-thumb, input[type=range].range-slider.range-slider--danger:not(.disabled):active::-ms-thumb {
  box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.25);
}
input[type=range].range-slider.range-slider--warning::-webkit-slider-thumb {
  background: #ffc107;
}
input[type=range].range-slider.range-slider--warning::-moz-range-thumb {
  background: #ffc107;
}
input[type=range].range-slider.range-slider--warning::-ms-thumb {
  background: #ffc107;
}
input[type=range].range-slider.range-slider--warning:not(.disabled):focus::-webkit-slider-thumb, input[type=range].range-slider.range-slider--warning:not(.disabled):active::-webkit-slider-thumb {
  box-shadow: 0 0 0 0.2rem rgba(255, 193, 7, 0.25);
}
input[type=range].range-slider.range-slider--warning:not(.disabled):focus::-moz-range-thumb, input[type=range].range-slider.range-slider--warning:not(.disabled):active::-moz-range-thumb {
  box-shadow: 0 0 0 0.2rem rgba(255, 193, 7, 0.25);
}
input[type=range].range-slider.range-slider--warning:not(.disabled):focus::-ms-thumb, input[type=range].range-slider.range-slider--warning:not(.disabled):active::-ms-thumb {
  box-shadow: 0 0 0 0.2rem rgba(255, 193, 7, 0.25);
}
input[type=range].range-slider.range-slider--info::-webkit-slider-thumb {
  background: #17a2b8;
}
input[type=range].range-slider.range-slider--info::-moz-range-thumb {
  background: #17a2b8;
}
input[type=range].range-slider.range-slider--info::-ms-thumb {
  background: #17a2b8;
}
input[type=range].range-slider.range-slider--info:not(.disabled):focus::-webkit-slider-thumb, input[type=range].range-slider.range-slider--info:not(.disabled):active::-webkit-slider-thumb {
  box-shadow: 0 0 0 0.2rem rgba(23, 162, 184, 0.25);
}
input[type=range].range-slider.range-slider--info:not(.disabled):focus::-moz-range-thumb, input[type=range].range-slider.range-slider--info:not(.disabled):active::-moz-range-thumb {
  box-shadow: 0 0 0 0.2rem rgba(23, 162, 184, 0.25);
}
input[type=range].range-slider.range-slider--info:not(.disabled):focus::-ms-thumb, input[type=range].range-slider.range-slider--info:not(.disabled):active::-ms-thumb {
  box-shadow: 0 0 0 0.2rem rgba(23, 162, 184, 0.25);
}
input[type=range].range-slider.range-slider--light::-webkit-slider-thumb {
  background: #f8f9fa;
}
input[type=range].range-slider.range-slider--light::-moz-range-thumb {
  background: #f8f9fa;
}
input[type=range].range-slider.range-slider--light::-ms-thumb {
  background: #f8f9fa;
}
input[type=range].range-slider.range-slider--light:not(.disabled):focus::-webkit-slider-thumb, input[type=range].range-slider.range-slider--light:not(.disabled):active::-webkit-slider-thumb {
  box-shadow: 0 0 0 0.2rem rgba(248, 249, 250, 0.25);
}
input[type=range].range-slider.range-slider--light:not(.disabled):focus::-moz-range-thumb, input[type=range].range-slider.range-slider--light:not(.disabled):active::-moz-range-thumb {
  box-shadow: 0 0 0 0.2rem rgba(248, 249, 250, 0.25);
}
input[type=range].range-slider.range-slider--light:not(.disabled):focus::-ms-thumb, input[type=range].range-slider.range-slider--light:not(.disabled):active::-ms-thumb {
  box-shadow: 0 0 0 0.2rem rgba(248, 249, 250, 0.25);
}
input[type=range].range-slider.range-slider--dark::-webkit-slider-thumb {
  background: #343a40;
}
input[type=range].range-slider.range-slider--dark::-moz-range-thumb {
  background: #343a40;
}
input[type=range].range-slider.range-slider--dark::-ms-thumb {
  background: #343a40;
}
input[type=range].range-slider.range-slider--dark:not(.disabled):focus::-webkit-slider-thumb, input[type=range].range-slider.range-slider--dark:not(.disabled):active::-webkit-slider-thumb {
  box-shadow: 0 0 0 0.2rem rgba(52, 58, 64, 0.25);
}
input[type=range].range-slider.range-slider--dark:not(.disabled):focus::-moz-range-thumb, input[type=range].range-slider.range-slider--dark:not(.disabled):active::-moz-range-thumb {
  box-shadow: 0 0 0 0.2rem rgba(52, 58, 64, 0.25);
}
input[type=range].range-slider.range-slider--dark:not(.disabled):focus::-ms-thumb, input[type=range].range-slider.range-slider--dark:not(.disabled):active::-ms-thumb {
  box-shadow: 0 0 0 0.2rem rgba(52, 58, 64, 0.25);
}
input[type=range].range-slider::-webkit-slider-thumb {
  -webkit-appearance: none;
  box-sizing: border-box;
  border: none;
  border-radius: 50%;
  background: #007bff;
  height: 20px;
  width: 20px;
  cursor: pointer;
  transition: box-shadow 0.5s ease;
  margin-top: -8px;
}
input[type=range].range-slider::-moz-range-thumb {
  -webkit-appearance: none;
  box-sizing: border-box;
  border: none;
  border-radius: 50%;
  background: #007bff;
  height: 20px;
  width: 20px;
  cursor: pointer;
  transition: box-shadow 0.5s ease;
}
input[type=range].range-slider::-ms-thumb {
  -webkit-appearance: none;
  box-sizing: border-box;
  border: none;
  border-radius: 50%;
  background: #007bff;
  height: 20px;
  width: 20px;
  cursor: pointer;
  transition: box-shadow 0.5s ease;
  margin-top: 0;
}
input[type=range].range-slider.range-slider--sm::-webkit-slider-thumb {
  height: 16px;
  width: 16px;
  margin-top: -6px;
}
input[type=range].range-slider.range-slider--sm::-moz-range-thumb {
  height: 16px;
  width: 16px;
}
input[type=range].range-slider.range-slider--sm::-ms-thumb {
  height: 16px;
  width: 16px;
  margin-top: 0;
}
input[type=range].range-slider.range-slider--lg::-webkit-slider-thumb {
  height: 24px;
  width: 24px;
  margin-top: -9px;
}
input[type=range].range-slider.range-slider--lg::-moz-range-thumb {
  height: 24px;
  width: 24px;
}
input[type=range].range-slider.range-slider--lg::-ms-thumb {
  height: 24px;
  width: 24px;
  margin-top: 0;
}
input[type=range].range-slider.disabled::-webkit-slider-thumb {
  background: #e9e9e9;
  cursor: not-allowed;
}
input[type=range].range-slider.disabled::-moz-range-thumb {
  background: #e9e9e9;
  cursor: not-allowed;
}
input[type=range].range-slider.disabled::-ms-thumb {
  background: #e9e9e9;
  cursor: not-allowed;
}
input[type=range].range-slider::-ms-tooltip {
  display: none;
}

.range-slider__wrap {
  position: relative;
  display: block;
  height: calc(1.5em + 0.75rem + 2px);
}
.range-slider__wrap .range-slider__tooltip {
  display: block;
  position: absolute;
  width: 0;
  height: 26px;
  text-align: center;
  overflow: visible;
  opacity: 0;
  transition: opacity 0.15s linear;
  z-index: 1070;
}
.range-slider__wrap .range-slider__tooltip.range-slider__tooltip--top {
  bottom: calc(0.75em + 0.375rem + 1px + .4rem + 10px + 3px);
  top: auto;
}
.range-slider__wrap .range-slider__tooltip.range-slider__tooltip--bottom {
  top: calc(0.75em + 0.375rem + 1px + .4rem + 10px + 3px);
  bottom: auto;
}
.range-slider__wrap .range-slider__tooltip.range-slider__tooltip--on {
  opacity: 0.9;
}
.range-slider__wrap .range-slider__tooltip.range-slider__tooltip--on.range-slider__tooltip--disabled {
  opacity: 0.25;
}
.range-slider__wrap .range-slider__tooltip .range-slider__tooltip__label {
  position: absolute;
  transform: translateX(-50%);
  max-width: 200px;
  padding: 0 0.5rem;
  height: 26px;
  line-height: 26px;
  color: white;
  text-align: center;
  background-color: black;
  opacity: 0.9;
  border-radius: 0.25rem;
  box-sizing: border-box;
  font-size: 0.875rem;
  white-space: nowrap;
}
.range-slider__wrap .range-slider__tooltip .range-slider__tooltip__caret {
  position: absolute;
  transform: translateX(-50%);
  display: block;
  width: 0.8rem;
  height: 0.4rem;
  opacity: 0.9;
}
.range-slider__wrap .range-slider__tooltip .range-slider__tooltip__caret::before {
  position: absolute;
  content: "";
  border-color: transparent;
  border-style: solid;
  left: 0;
}
.range-slider__wrap .range-slider__tooltip.range-slider__tooltip--top .range-slider__tooltip__caret {
  bottom: -0.4rem;
}
.range-slider__wrap .range-slider__tooltip.range-slider__tooltip--top .range-slider__tooltip__caret::before {
  top: 0;
  border-width: 0.4rem 0.4rem 0;
  border-top-color: black;
}
.range-slider__wrap .range-slider__tooltip.range-slider__tooltip--bottom .range-slider__tooltip__caret {
  top: -0.4rem;
}
.range-slider__wrap .range-slider__tooltip.range-slider__tooltip--bottom .range-slider__tooltip__caret::before {
  bottom: 0;
  border-width: 0 0.4rem 0.4rem;
  border-bottom-color: black;
}
.range-slider__wrap.range-slider__wrap--sm {
  height: calc(1.5em + 0.5rem + 2px);
  font-size: 0.875rem;
}
.range-slider__wrap.range-slider__wrap--sm .range-slider__tooltip--top {
  bottom: calc(0.75em + 0.25rem + 1px + .4rem + 8px + 3px);
}
.range-slider__wrap.range-slider__wrap--sm .range-slider__tooltip--bottom {
  top: calc(0.75em + 0.25rem + 1px + .4rem + 8px + 3px);
}
.range-slider__wrap.range-slider__wrap--lg {
  height: calc(1.5em + 1rem + 2px);
  font-size: 1.25rem;
}
.range-slider__wrap.range-slider__wrap--lg .range-slider__tooltip--top {
  bottom: calc(0.75em + 0.5rem + 1px + .4rem + 12px + 3px);
}
.range-slider__wrap.range-slider__wrap--lg .range-slider__tooltip--bottom {
  top: calc(0.75em + 0.5rem + 1px + .4rem + 12px + 3px);
}
.range-slider__wrap:hover .range-slider__tooltip:not(.range-slider__tooltip--off):not(.range-slider__tooltip--disabled) {
  opacity: 0.9;
}
.range-slider__wrap:hover .range-slider__tooltip--disabled:not(.range-slider__tooltip--off) {
  opacity: 0.25;
}