/* MUI */


.MuiInputBase-root {
	font-size:18px;
}
.MuiFormControl-root {
	width:100%;	
}

.gridSearch {
	width:calc(100% - 36px - 44px - 48px);
	margin-top:-2px;
	padding-right:4px;
}

.MuiInputBase-input {
	font-family: 'VCR', 'VT323', monospace;
	text-align: center;

}

.MuiInput-underline:after {
	border-bottom: 2px solid var(--primaryColor);
	
}
.MuiFormControl-root.gridSearch {
	margin-bottom:20px;
}

