@font-face {
  font-family: 'Pixeboy';
  src: url('fonts/pixeboy.woff') format('woff'), /* Pretty Modern Browsers */
       url('fonts/pixeboy.ttf')  format('truetype'); /* Safari, Android, iOS */
}
@font-face {
  font-family: 'VCR';
  src: 
       url('fonts/vcr.ttf')  format('truetype'); /* Safari, Android, iOS */
}
@font-face {
  font-family: 'ArcadePi';
  src: 
       url('fonts/arcadepi.ttf')  format('truetype'); /* Safari, Android, iOS */
}

body {
  margin: 0;
/*  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;*/
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  font-family: 'VCR', 'VT323', monospace;
  font-size:12px;
  background-color:#211918;
}

p {
	font-size: 1.4em;
}

.workersSelect {
	color:#FFF;
}
.workersSelect a, .workersSelect a:hover {
	color:#FFF;
}


code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

#bob_christine_container {
	margin-top:40px;
}
/*

.btn-primary {
    color: #fff;
    background-color: #910b0b;
    border-color: #910b0b;
}

.btn-primary:hover, .btn-primary a {
    color: #fff;
    background-color: #7f1011;
    border-color: #7f1011;
}

.btn-primary:focus, .btn-primary:active, .btn-primary.active {
    color: #fff;
    background-color: #7f1011;
    border-color: #7f1011;
}
.btn-primary.disabled,.btn-primary:disabled{
	color:#fff;
	background-color:#7f1011;
	border-color:#7f1011;
}

.btn-primary:not(:disabled):not(.disabled).active, .btn-primary:not(:disabled):not(.disabled):active, .show>.btn-primary.dropdown-toggle {
	color: #fff;
    background-color: #7f1011;
    border-color: #7f1011;
}

.btn-primary:not(:disabled):not(.disabled).active:focus, .btn-primary:not(:disabled):not(.disabled):active:focus, .show>.btn-primary.dropdown-toggle:focus {
    box-shadow: 0 0 0 0.2rem #7f1011;
}

.btn-primary.focus, .btn-primary:focus {

    box-shadow: 0 0 0 0.2rem rgb(127 16 17 / 25%);
}
*/

.btn-secondary {
    color: #fff;
    background-color: var(--secondaryColor);
    border-color: var(--secondaryColor);
}

.btn-secondary:hover, .btn-secondary a {
    background-color: var(--secondaryColorDarken);
    border-color: var(--secondaryColorDarken);
}

.btn-secondary:focus, .btn-secondary:active, .btn-secondary.active {
	background-color: var(--secondaryColorDarken);
    border-color: var(--secondaryColorDarken);
}
.btn-secondary.disabled,.btn-secondary:disabled{
	background-color: var(--secondaryColorDarken);
    border-color: var(--secondaryColorDarken);
}

.btn-secondary:not(:disabled):not(.disabled).active, .btn-secondary:not(:disabled):not(.disabled):active, .show>.btn-secondary.dropdown-toggle {
    background-color: var(--secondaryColorDarken);
    border-color: var(--secondaryColorDarken);
}

.btn-secondary:not(:disabled):not(.disabled).active:focus, .btn-secondary:not(:disabled):not(.disabled):active:focus, .show>.btn-secondary.dropdown-toggle:focus {
    box-shadow: 0 0 0 0.2rem rgb(0 0 0 / 33%);
}

.btn-secondary.focus, .btn-secondary:focus {
    box-shadow: 0 0 0 0.2rem rgb(0 0 0 / 33%);
}




.btn-warning {
    color: #000;
    background-color: var(--rare0Darken);
    border-color: var(--rare0Darken);
}
.btn-warning:hover, .btn-warning a {
    background-color: var(--rare0DarkenMore);
    border-color: var(--rare0DarkenMore);
}
.btn-warning:focus, .btn-warning:active, .btn-warning.active {
    background-color: var(--rare0DarkenMore);
    border-color: var(--rare0DarkenMore);
}
.btn-warning.disabled,.btn-warning:disabled{
    background-color: var(--rare0DarkenMore);
    border-color: var(--rare0DarkenMore);
}
.btn-warning:not(:disabled):not(.disabled).active, .btn-warning:not(:disabled):not(.disabled):active, .show>.btn-warning.dropdown-toggle {
    background-color: var(--rare0DarkenMore);
    border-color: var(--rare0DarkenMore);
}
.btn-warning:not(:disabled):not(.disabled).active:focus, .btn-warning:not(:disabled):not(.disabled):active:focus, .show>.btn-warning.dropdown-toggle:focus {
    box-shadow: 0 0 0 0.2rem rgb(0 0 0 / 33%);
}
.btn-warning.focus, .btn-warning:focus {
    box-shadow: 0 0 0 0.2rem rgb(0 0 0 / 33%);
}

.btn-primary {
    color: #fff;
    background-color: var(--primaryColor);
    border-color: var(--primaryColor);
}

.btn-primary:hover, .btn-primary a {
    background-color: var(--primaryColorDarken);
    border-color: var(--primaryColorDarken);
}

.btn-primary:focus, .btn-primary:active, .btn-primary.active {
	background-color: var(--primaryColorDarken);
    border-color: var(--primaryColorDarken);
}
.btn-primary.disabled,.btn-primary:disabled{
	background-color: var(--primaryColorDarken);
    border-color: var(--primaryColorDarken);
}

.btn-primary:not(:disabled):not(.disabled).active, .btn-primary:not(:disabled):not(.disabled):active, .show>.btn-primary.dropdown-toggle {
    background-color: var(--primaryColorDarken);
    border-color: var(--primaryColorDarken);
}

.btn-primary:not(:disabled):not(.disabled).active:focus, .btn-primary:not(:disabled):not(.disabled):active:focus, .show>.btn-primary.dropdown-toggle:focus {
    box-shadow: 0 0 0 0.2rem rgb(0 0 0 / 33%);
}

.btn-primary.focus, .btn-primary:focus {
    box-shadow: 0 0 0 0.2rem rgb(0 0 0 / 33%);
}





#centering_container {

}

/* hide mobile version by default */
.mobile {
	display: none;
}
/* when screen is less than 600px wide
 show mobile version and hide desktop */
@media (max-width: 640px) {
	.mobile {
	  display: block;
	}
	.desktop {
	  display: none;
	}
}

#logo {
	font-size:3.0em;
	margin:0;
	padding:0px 0px 8px 0px;
	background-color:white;
	
}

.two_buttons {
	width:100%;
}
.pad_two_buttons_left {
	padding-right:20px;
	display:inline;
}


#center {
	text-align:center;
}

#cs_header {
	background-color:#141414;
	width:100%;
	height:120px;
	padding-top:25px;
}

#operaHolder {
	background-image: url('./img/opera.png');
	background-position: top center;
	width:100%;
	min-height:640px;
	background-repeat:no-repeat;
	margin:0px auto 0px auto;
}
#operaHolderMansion {
	background-image: url('./img/mansion.png');
	background-position: top center;
	width:100%;
	min-height:640px;
	background-repeat:no-repeat;
	margin:0px auto 0px auto;
}
#operaHolderAbout {
	background-image: url('./img/opera_dark.png');
	background-position: top center;
	width:100%;
	min-height:640px;
	background-repeat:no-repeat;
	margin:0px auto 0px auto;
}
#operaHolderFarm {
	background-image: url('./img/opera_farm.png');
	background-position: top center;
	width:100%;
	min-height:640px;
	background-repeat:no-repeat;
	margin:0px auto 0px auto;
}
#operaHolderStake {
	/*background-image: url('./img/opera_farm.png');*/
	background-position: top center;
	width:100%;
	min-height:340px;
	background-repeat:no-repeat;
	margin:0px auto 0px auto;
	background-color: #FFF;
	color: #000;
}
#operaHolderDuelSplash {
	background-image: url('./img/duel_splash.jpg');
	background-position: top center;
	background-size: 170%;
	width:100%;
	min-height:640px;
	background-repeat:no-repeat;
	margin:0px auto 0px auto;
	color: #FFF;
	padding-top: 40%;
	font-size: 14px;
}
#operaHolderCraftingSplash {

	background-image: url('./img/craft_splash.jpg');
	background-position: top center;
	background-size: 170%;
	width:100%;
	min-height:640px;
	background-repeat:no-repeat;
	margin:0px auto 0px auto;
	color: #FFF;
	padding-top: 40%;
	font-size: 14px;
}
.duels_splash_center_container {
	width: 480px;
	margin: 0 auto;
}
.appOperaDuelSplash {
	width:100%;
	/*background-image: url('./img/opera_farm_sliver.png');*/

	background-position: top center;
	background-repeat: repeat-x;
	min-height:600px;
	background-color:#111111;
}
#operaHolderStreets {
	background-image: url('./img/streets_of_paris.png');
	background-position: top center;
	width:100%;
	min-height:640px;
	background-repeat:no-repeat;
	margin:0px auto 0px auto;
	background-color: #1e1312;
	color: #FFF;
/*	z-index: -2;
	position: absolute;
	*/
}

.duel_title {
	font-weight: bold;
	color: #FFF;
	font-size: 36px;
	width: 100%;
	text-shadow: 2px 2px 0px #000000;
	line-height: 1.1em;
}
.duel_subtitle2 {
	margin: -10px auto 0px auto;
	color: #FFF;
	font-size: 20px;
	width: 80%;
	text-shadow: 2px 2px 0px #000000;
	line-height: 1.1em;
}
.duel_subtitle {
	margin: 0 auto;
	color: #FFF;
	font-size: 20px;
	width: 100%;
	padding: 0px 20px;
	text-shadow: 2px 2px 0px #000000;
}
@media (max-width: 640px) {
	.duel_subtitle {
		margin-top: -100px;
	}
	.duel_subtitle2 {
		margin-top: -10px;
		font-size: 12px;
	}
}



/*.addRip {
	background-image: url('./img/mm_riplogo512.png');
	background-size: contain;
	width:18px;
	height:18px;
	background-color:#2e201d;
	background-repeat:no-repeat;
	background-position: center center;
	image-rendering: pixelated;
	display:inline-block;
	vertical-align: top;
}

.addFoo {
	background-image: url('./img/mm_logo512.png');
	background-size: contain;
	width:18px;
	height:18px;
	background-color:#2e201d;
	background-repeat:no-repeat;
	background-position: center center;
	image-rendering: pixelated;
	display:inline-block;
	vertical-align: top;
}*/
.addRip {
	margin-top:6px;
	background-image: url('./img/mm_riplogo512.png');
	background-size: contain;
	width:26px;
	height:26px;
	background-color:#2e201d;
	background-repeat:no-repeat;
	background-position: center center;
	image-rendering: pixelated;
	display:inline-block;
	vertical-align: top;
}
.addFoo {
	margin-top:6px;
	background-image: url('./img/mm_logo512.png');
	background-size: contain;
	width:26px;
	height:26px;
	background-color:#2e201d;
	background-repeat:no-repeat;
	background-position: center center;
	image-rendering: pixelated;
	display:inline-block;
	vertical-align: top;
}



.clear {
	clear:both;
}
.paginationDiv {
	float:left;
	width:100%;
	margin:30px 0px;
}


.aboutMale {
	float:left;
	width:50%;
}
.aboutFemale {
	float:left;
	width:50%;
}

.aboutMale a, .aboutFemale a, .aboutMale a:hover, .aboutFemale a:hover {
	color:#FFF;
}


.appOperaStake {
	width:100%;
	/*background-image: url('./img/opera_farm_sliver.png');*/

	background-position: top center;
	background-repeat: repeat-x;
	min-height:400px;
	background-color:#FFF;
}



.appOperaFarm {
	width:100%;
	background-image: url('./img/opera_farm_sliver.png');

	background-position: top center;
	background-repeat: repeat-x;
	min-height:600px;
	background-color:#1f8d1d;
}


.appOperaAbout {
	width:100%;
	background-image: url('./img/opera_dark_sliver.png');

	background-position: top center;
	background-repeat: repeat-x;
	min-height:600px;
	background-color:#0d376a;
}

.appOperaMansion {
	width:100%;
	background-image: url('./img/mansion_sliver.png');

	background-position: top center;
	background-repeat: repeat-x;
	min-height:600px;
	background-color:#311302;
}

.dirt {
	width:100%;
	background-image: url('./img/dirt.png');
	background-size: 1050px 46px;
	background-position: top center;
	background-repeat: no-repeat;
	min-height:200px;
	margin:0px auto 0px auto;
	background-color:#382621;
}

.dirt_skellies {
	width:100%;
	background-image: url('./img/dirt_skellies.png');
	background-size: 1050px 400px;
	background-position:  top center;
	background-repeat: no-repeat;
	min-height:200px;
	margin:0px auto 0px auto;
	background-color:#382621;
}

#cs_sliver {
	width:100%;
	background-image: url('./img/cs_sliver.png');
	background-size: contain;
	background-position: center;
	background-repeat: repeat-x;
	height:611px;
}
#splash_center {

	background-image: url('./img/coming_soon2.png');
	background-size: 1200px  611px;
	background-position: center center;
	width:100%;
	height:611px;
	background-repeat:no-repeat;
	margin:0px auto 0px auto;


}





#curtainSquare {
	width:360px;
	margin:0 auto;
	padding:160px 0px 0px 0px;
}

#curtainSquareAbout {
	width:360px;
	margin:0 auto;
	padding:120px 0px 0px 0px;
}
#curtainSquareMansion {
	width:360px;
	margin:0 auto;
	padding:40px 0px 0px 0px;
}





.doc_align_left {
	padding-top:20px;
	text-align:left;
}

#opera_text_holder {
	width:320px;
	margin: 100px auto 0px auto;
}

.trans_font {


}



.cs_logo {
	display:block;
	color:white;
	font-size:7.0em;
	letter-spacing: 0.1em;
	margin-left:12px;
}

.cs_3 {

	display:block;
	color:#4f1b20;
	font-size:1.6em;
	letter-spacing: -0.0em;
	font-weight:bold;
}


.cs_3_about {

	display:block;
	color:#FFF;
	font-size:1.6em;
	letter-spacing: -0.0em;
	font-weight:bold;
}

.cs_2 {

	display:block;
	color:#910b0b;
	font-size:2.5em;
	letter-spacing: 0.1em;
	font-weight:bold;
	font-family: 'ArcadePi', 'VT323', monospace;
	 -webkit-transform: scaleX(0.75);
    transform: scaleX(0.75);
   
}

.cs_2_about {

	display:block;
	color:#FFF;
	font-size:2.5em;
	letter-spacing: 0.1em;
	font-weight:bold;
	font-family: 'ArcadePi', 'VT323', monospace;
	 -webkit-transform: scaleX(0.75);
    transform: scaleX(0.75);
   
}
.cs_4 {
	display:block;
	color:#FFF;
	font-size:1.4em;
	letter-spacing: -0.025em;
}
.cs_4 a, .cs_4 a:hover {
	text-decoration: none;
	color:#FFF;
}

.cs_5 {
	display:block;
	color:#000;
	font-size:1.5em;
	letter-spacing: -0.0em;
}
.cs_5 a, .cs_5 a:hover {
	text-decoration: none;
	color:#000;
}
.cs_6 {
	display:block;
	
	font-size:1.0em;
	letter-spacing: -0.0em;
}
.cs_6 a, .cs_6 a:hover {
	text-decoration: none;
}


.cs_6_about {
	display:block;
	color:#FFF;
	font-size:1.2em;
	font-weight:bold;
	letter-spacing: -0.0em;
	text-shadow: 1px 1px 0px #000000;
}
.cs_6_about a, .cs_6_about a:hover {
	text-decoration: none;
}

/* INFO */

.fantum_name_info {
	width:100%;
	display:block;
	color:#000;
	font-size:2.5em;
	letter-spacing: 0.1em;
	font-weight:bold;
	font-family: 'ArcadePi', 'VT323', monospace;
	 -webkit-transform: scaleX(0.75);
    transform: scaleX(0.75);
    line-height: 1.0em;
    padding-bottom:0px;

    text-shadow:2px 2px #000;


}
.fantum_name_info_shift {
	width:100%;
	display:block;
	color:#000;
	font-size:2.5em;
	letter-spacing: 0.1em;
	font-weight:bold;
	font-family: 'ArcadePi', 'VT323', monospace;
	 -webkit-transform: scaleX(0.75);
    transform: scaleX(0.75);
    line-height: 1.0em;
    padding-bottom:0px;
    margin-left:2px;

        background-size: 100%;
    -webkit-background-clip: text;
    -moz-background-clip: text;
    -webkit-text-fill-color: transparent; 
    -moz-text-fill-color: transparent;

    margin-top:-33px;

}

.shadow_colored {
	text-shadow:1px 1px #000;
}

.fantum_info_two {
	display:block;
	padding-bottom: 2px;
	text-shadow:1px 1px #000;
	font-size:1.2em;
}

.modal_content_width {
	width:480px;
	font-size:16px;
	margin:0 auto;
}
@media (max-width: 660px) {
	.modal_content_width {
		width:280px;
		
	}
}



.infoFantumContainer {
	margin-top:-20px;
}


.infoFantumContainerTint {
	position: absolute;
	margin-left: 40px;
	margin-top:-20px;
	mix-blend-mode: screen;
}




/* FIND */

.rarityColor_0_roll {
	color:var(--rare0);
}
.rarityColor_1_roll {
	color:var(--rare1);
}
.rarityColor_2_roll {
	color:var(--rare2);
}
.rarityColor_3_roll {
	color:var(--rare3);
}

.rarityColor_0_roll a, .rarityColor_0_roll a:hover {
	color:var(--rare0);
}
.rarityColor_1_roll a, .rarityColor_1_roll a:hover {
	color:var(--rare1);
}
.rarityColor_2_roll a, .rarityColor_2_roll a:hover {
	color:var(--rare2);
}
.rarityColor_3_roll a, .rarityColor_3_roll a:hover {
	color:var(--rare3);
}

.rarityColor_0_rank {
	color:var(--rare0New);
}
.rarityColor_1_rank {
	color:var(--rare1New);
}
.rarityColor_2_rank {
	color:var(--rare2New);
}
.rarityColor_3_rank {
	color:var(--rare3New);
}
.rarityColor_4_rank {
	color:var(--rare4New);
}
.rarityColor_5_rank {
	color:var(--rare5New);
}

.rarityColor_0_rank a, .rarityColor_0_rank a:hover {
	color:var(--rare0New);
}
.rarityColor_1_rank a, .rarityColor_1_rank a:hover {
	color:var(--rare1New);
}
.rarityColor_2_rank a, .rarityColor_2_rank a:hover {
	color:var(--rare2New);
}
.rarityColor_3_rank a, .rarityColor_3_rank a:hover {
	color:var(--rare3New);
}
.rarityColor_4_rank a, .rarityColor_4_rank a:hover {
	color:var(--rare4New);
}
.rarityColor_5_rank a, .rarityColor_5_rank a:hover {
	color:var(--rare5New);
}

.wallet_belong {
	font-size:1.0em;
}
.found_name {
	width:100%;
	display:block;
/*	color:#FFF;*/
	font-size:2.5em;
	letter-spacing: 0.1em;
	font-weight:bold;
	font-family: 'ArcadePi', 'VT323', monospace;
	 -webkit-transform: scaleX(0.75);
    transform: scaleX(0.75);
    line-height: 1.0em;
    text-shadow: 2px 2px 0px #000000;
}
.found_name_white_text {
	color: #FFF;
}
.about_contains_info {
	margin:0 auto;
	width:640px;
	padding:20px;
	min-height:300px;
}
.about_contains_info_mobile {
	margin:0 auto;
	width:320px;
	padding:20px;
	min-height:300px;
}
.float_text_left {
	width:66%;
	float:left;
	text-align: left;
}
.float_icon_right {
	width:23%;
	float:left;
	
}
.float_text_right {
	width:66%;
	float:left;
	text-align: right;
}
.float_icon_left {
	width:23%;
	float:left;

}

.float_text_left_mobile {
	padding-top: 20px;
	width:100%;
	float:left;
	text-align:center;
}
.float_icon_right_mobile {
	padding-top: 20px;
	width:100%;
	float:left;
	
}
.float_text_right_mobile {
	padding-top: 20px;
	width:100%;
	float:left;
	text-align:center;
}
.float_icon_left_mobile {
	padding-top: 20px;
	width:100%;
	float:left;

}

.non_fantum_rendering {
	image-rendering: auto;
}
.appOperaGothumsFind {
	background-image: url('./img/gothums_find_sliver.png');
	width:100%;
	background-position: center -330px;
	background-repeat:repeat-x;
	background-color:#191919;
	margin: 0px;
	padding: 0px;
}

#operaHolderFind {
	background-image: url('./img/find.png');
	background-position: top center;
	width:100%;
	min-height:820px;
	background-repeat:no-repeat;
	padding-bottom:0px;
	margin:0px auto 0px auto;

}
.find_dirt2 {
	width:100%;
	background-image: url('./img/dungeon_brick3.png');
	background-position: center -330px;
	background-repeat:repeat-y;
	margin:0px auto 0px auto;
	

	image-rendering: pixelated;



	min-height:820px;

}
.find_dirt {
	width:100%;
	background-image: url('./img/dungeon_brick2.png');
	background-position: center -330px;
	background-repeat:repeat-y;
	margin:0px auto 0px auto;
	background-color:#281b19;

	image-rendering: pixelated;



	min-height:680px;

}
.find_dirt_about {
	width:100%;
	background-image: url('./img/dungeon_brick_landing.png');
	background-position: center -330px;
	background-repeat:repeat-y;
	margin:0px auto 0px auto;
	background-color:#281b19;

	image-rendering: pixelated;
	color:#FFF;
	
}
.find_black_about {
	width:100%;
	background-image: url('./img/black_landing.png');
	background-position: center -330px;
	background-repeat:repeat-y;
	margin:0px auto 0px auto;
	background-color:#0f0f0f;

	image-rendering: pixelated;
	color:#FFF;
	
}



.findSquare {
	margin:0 auto;
	padding:40px 10px 10px 10px;
	width:360px;
}

@keyframes rotation {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(359deg);
  }
}

.rotate {
	animation: rotation 0.5s infinite linear;
}
#smallRotatingSkelly {
	background-image: url('./img/rotating_skelly.png');
	width:30px;
	height:30px;
	background-size:contain;
	background-repeat: no-repeat;
	background-position: center center;
	
	margin:10px auto 0px auto;
}
#rotatingSkellyDuel {
	background-image: url('./img/rotating_skelly.png');
	width:100px;
	height:100px;
	background-size:contain;
	background-repeat: no-repeat;
	background-position: center center;
	
	margin:0px auto 0px auto;
}
#rotatingSkelly {
	background-image: url('./img/rotating_skelly.png');
	width:100px;
	height:100px;
	background-size:contain;
	background-repeat: no-repeat;
	background-position: center center;
	
	margin:70px auto 0px auto;
}
#rotatingSkellyAbout {
	background-image: url('./img/rotating_skelly.png');
	width:50px;
	height:50px;
	background-size:contain;
	background-repeat: no-repeat;
	background-position: center center;
	
	margin:70px auto 0px auto;
}

.found_line_one {
	font-size:1.2em;
	color:#FFF;
	font-weight:bold;

}
.found_line_one_one {
	font-size:1.0em;
	font-weight:bold;
	color:#FFF;
}
.found_line_two {
	font-size:1.2em;
	color:#FFF;
	font-weight:normal;
}




.rarityColor_0 {
	color:var(--rare0);
}
.rarityColor_1 {
	color:var(--rare1);
}
.rarityColor_2 {
	color:var(--rare2);
}
.rarityColor_3 {
	color:var(--rare3);
}

.rarityColor_0 a, .rarityColor_0 a:hover {
	color:var(--rare0);
}
.rarityColor_1 a, .rarityColor_1 a:hover {
	color:var(--rare1);
}
.rarityColor_2 a, .rarityColor_2 a:hover {
	color:var(--rare2);
}
.rarityColor_3 a, .rarityColor_3 a:hover {
	color:var(--rare3);
}


.pagination {
	display:block;
	color:#000;
	font-size:1.0em;
	letter-spacing: -0.0em;
}
.pagination a, .pagination a:hover {
	text-decoration: underline;
	color:#000;
	cursor: pointer;
}

#main_text {
	margin: 0 auto;
	width:calc(100% - 0px);
	padding:20px 0px;
	background-color:white;
	border-bottom:6px solid black;
}

.menu_cont {
	
	margin: 0 auto;
	width:100%;
	padding:8px 0px;
	color:#FFF;
	background-color:#141414;
	/*background-image: url('./img/top_touch.png');
	background-position:top;
	background-repeat: no-repeat;*/
	background-size:4000px 20px;
}


.aboutFantumContainer {
	margin-top:-10px;
}

.fantom_centerer {
	margin: 0px auto 0px auto;
}

.fantom_container {
	position:absolute;
}

.browseShadow {
/*	-webkit-filter: drop-shadow(0px 2px 0px #ddd);
    filter: drop-shadow(0px 2px 0px #ddd);*/
}
.fantom_layer {
	position:absolute;
	top:0px;
	
	

}
.renderPixelated {
	image-rendering: pixelated;
}
.fantom_dims {
	image-rendering: pixelated;
	
}

#logo a, #logo a:hover {
	text-decoration: none;
	color:#000;
}
.browseItemEven {
	margin: 22px auto;
	font-size: 20px;
	width: 640px;
	height: 128px;
	border-left:3px dashed #202020;
	border-right:3px dashed #202020;
	

}
.browseItemOdd {
	margin: 22px auto;
	font-size: 20px;
	width: 640px;
	height: 128px;
	border-left:3px dashed #303030;
	border-right:3px dashed #303030;
	
}

.leftBrowse {
	float:left;
	width:128px;
	margin-left:10px;
}
.rightBrowse {
	float:left;
	text-align:right;
	width:calc(100% - 138px);
	margin-top:24px;
	padding-right:26px;
}


.theFantumName a, .theFantumName a:hover {
	
}
.blackFantumName a, .blackFantumName a:hover {
	color:#000;
}

.blue_page .blackFantumName a, .blue_page .blackFantumName a:hover {
	color: #FFF;
}



.fantumNameBrowse {
	color:#000;

}

.browseTextLineBigger {
	display:block;
	line-height: 1em;
	font-size:0.9em;
	color:#000;
}
.browseTextLine {
	display:block;
	line-height: 1em;
	font-size:0.8em;
}
.browseTextLine a, .browseTextLine a:hover {
	color:#000;
}
.browseTextLineSmaller {
	padding:6px 0px;
	display:block;
	line-height: 1em;
	font-size:0.7em;
	color:#000;
}

.padMenuButtonRight {
	margin-right:8px;
}

.connectContainer {
	position:relative;
	left: calc(100% - 460px);
	top:-46px;
	text-align:right;
	width:460px;
}

.topHeader {
	height:96px;
	background-color:#000;
	text-align:left;
	padding:22px 22px;
}

.centeredLogoContainer {
	margin:0 auto;
	width:249px;
	height:60px;
}

@media only screen and (max-width: 640px) {
  .topHeader {
		
		padding:22px 12px;
	}


}

.rareText {
	color:blue;
}
.epicText {
	color:purple;
}
.legendaryText {
	color:orange;
}

p {
	margin:0;
}


.textBlock {
	border:dashed 5px #FFF;
	margin:0px auto;
	padding:10px;
	text-align:center;
	width:calc(440px - 80px);
	color:white;
}
.textBlockOuter {
	background-color:#3d69f9;
	margin:0 auto 10px auto;
	width:calc(440px - 60px);
	padding:10px 10px 10px 10px;
}

.noselect {
  -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Safari */
     -khtml-user-select: none; /* Konqueror HTML */
       -moz-user-select: none; /* Old versions of Firefox */
        -ms-user-select: none; /* Internet Explorer/Edge */
            user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome, Edge, Opera and Firefox */
}

.hidden {
	display:none;
}

/* HEADER */


#contains_top {
	width:100%;
	height:20px;
	background-color:#141414;
}
#left_menu_top {
	height:8px;
	background-color:#000;
	float:left;
}
#center_menu_top {
	height:1px;
	background-color:#000;
	float:left;
}
#right_menu_top {
	height:8px;
	background-color:#000;
	float:left;
}

.menu_cont {
	margin: -20px auto 0px auto;
	width:100%;
	padding:8px 0px;
	color:#FFF;
	background-color:#141414;

}

#contains_bot {
	width:100%;
	height:20px;
	float:left;
}
#left_menu_bot {
	height:8px;
	background-color:#141414;
	float:left;
}
#center_menu_bot {
	height:1px;
	background-color:#141414;
	float:left;
}
#right_menu_bot {
	height:8px;
	background-color:#141414;
	float:left;
}


.padMenuButtonRight {
	margin-right:8px;
}


.topHeader {
	height:96px;
	background-color:#000;
	text-align:left;
	padding:22px 22px;
}


@media only screen and (max-width: 600px) {
  .topHeader {
		
		padding:22px 22px;
	}
}

.left_menu_detail {
	width: calc(50% - 320px);
}
.right_menu_detail {
	width: calc(50% - 320px);
}
.page_width {
  width: 640px;
}
@media (max-width: 660px) {
	.left_menu_detail {
		width: calc(50% - 180px);
	}
	.right_menu_detail {
		width: calc(50% - 180px);
	}
	.page_width {
	  width: 360px;
	}
}

/* FOOTER */

.footer_text_link_smaller {
	display:block;
	color:#FFF;
	font-size:1.2em;
	letter-spacing: -0.025em;
}
.footer_text_link_smaller a, .footer_text_link_smaller a:hover {
	text-decoration: none;
	color:#FFF;
}

.footer_text_link {
	display:block;
	color:#FFF;
	font-size:1.4em;
	letter-spacing: -0.025em;
}
.footer_text_link a, .footer_text_link a:hover {
	text-decoration: none;
	color:#FFF;
}
.footer_text_link_two {
	color:#FFF;
	font-size:1.1em;
}
.footer_text_link_two a, .footer_text_link_two a:hover {
	text-decoration: none;
	color:#FFF;
}

.footer_cont {
	color:#53403b;
	font-size:1.0em;
}

.footerP {
	font-size:0.8em;
}


/* COMMON ELEMENTS */

.seat {
	background-image: url('./img/seat.png');
	background-size:contain;
	image-rendering: pixelated;
	background-position:1px 5px;
	height:34px;
	background-repeat:no-repeat;
}

.blue_page {
	width:100%;
	color: #FFF;
	background-color:#092e5a;
	min-height:200px;
}
.white_page {
	width:100%;
	background-color:#f8f8f8;
	min-height:200px;
}
.blue_page #gridFantumHolder {
	background-color:#092e5a;
}

.red_page #gridFantumHolder {
	background-color:#3a0001;
}
.red_page {
	width:100%;
	background-color:#2e0001;
	min-height:200px;
}
.centered_content {
	width:680px;
	margin:0 auto;
}
.black_page {
	width:100%;
	background-color:black;
	min-height:440px;
}
.dark_page {
	width:100%;
	color: #FFF;
	min-height:440px;
}
.dark_page_crafting {
	width:100%;
	color: #FFF;
	min-height:740px;
}

/* TOKEN PAGE ELEMENTS */

.token_page {
	width:100%;
	background-position: top center;
	background-repeat: repeat-x;
	min-height:400px;
	padding-bottom:20px;
}

.token_page_opera {
	background-image: url('./img/opera_sliver.png');
	background-color:#a3512b;
	color:#FFF;
}
.token_page_opera_gm {
	background-image: url('./img/opera_gm_sliver.png');
	background-color:var(--tokenPageColorsOperaGM2);
	background-position: 0px 2px;
	background-size: 100% 56%;
	color:#FFF;
}

.token_page_graveyard {
	
	background-color:#050e01;
	color:#FFF;
}

.token_page_opera_white {
	background-image: url('./img/opera_white_sliver.png');
	background-color:#e0e0e0;
}

.token_page_opera_black {
	background-image: url('./img/opera_black_sliver.png');
	background-color:#121212;
	color:#FFF;
}


#token_graveyard {
	background-image: url('./img/graveyard.png');
	background-position: top center;
	width:100%;
	min-height:570px;
	background-repeat:no-repeat;
	margin:0px auto -60px auto;
	color:#FFF;
	
}

#token_opera_gm {
	background-image: url('./img/opera_gm.png');
	background-position: top center;
	width:100%;
	min-height:570px;
	background-repeat:no-repeat;
	margin:0px auto -60px auto;
	color:#FFF;
	
}

#token_opera {
	background-image: url('./img/opera.png');
	background-position: top center;
	width:100%;
	min-height:570px;
	background-repeat:no-repeat;
	margin:0px auto -60px auto;
	color:#FFF;
	
}

#token_opera_white {
	background-image: url('./img/opera_white.png');
	background-position: top center;
	width:100%;
	min-height:570px;
	background-repeat:no-repeat;
	margin:0px auto -60px auto;
	color:#000;
}

#token_opera_black {
	background-image: url('./img/opera_black.png');
	background-position: top center;
	width:100%;
	min-height:570px;
	background-repeat:no-repeat;
	margin:0px auto -60px auto;
	color:#FFF;
}

#token_curtain {
	margin:0 auto;
	padding:136px 0px 0px 0px;
	width:360px;
	height: 640px;
}

.token_block_background {
	width:400px;
	margin:0 auto;
	padding-bottom:0px;
}


.token_block {
	border-radius: .25rem;
	text-align:center;
	width:400px;
	margin: 0px auto 0px auto;
	padding:10px;
	margin-bottom:10px;
	font-size:1.2em;
}
.actions {
	padding:10px 10px 5px 10px;
}
.token_block_opera {
	background-color:var(--tokenPageColorsOpera1);
}
.token_block_opera_gm {
	background-color:var(--tokenPageColorsOperaGM1);
}
.token_block_opera_white {
	background-color:var(--tokenPageColorsWhite1);
}
.token_block_opera_black {
	background-color:var(--tokenPageColorsBlack1);
}
.token_block_opera_green {
	background-color:#030901;
}
.token_white_block a, .token_white_block a:hover {
	color:#000;
} 
.token_black_block a, .token_black_block a:hover {
	color:#FFF;
} 
.token_green_block a, .token_green_block a:hover {
	color:#FFF;
} 

.token_details_full {
	text-align: left;
	width:calc(100% - 0px);
	float:left;
}

.token_details_full_right {
	text-align: right;
	width:calc(100% - 0px);
	float:left;
}


.token_details_left_more {
	text-align: left;
	width:calc(80% - 0px);
	float:left;
}
.token_details_right_less {
	text-align: right;
	width:calc(20% - 0px);
	float:right;
}

.token_details_left {
	text-align: left;
	width:calc(50% - 0px);
	float:left;
}
.token_details_right {
	text-align: right;
	width:calc(50% - 0px);
	float:right;
}

.token_action_button {
	padding:0px 0px;
	margin-bottom:5px;

}

.token_block_header {
	display:block;
	text-align: left;
	font-size:1.0em;
	letter-spacing: -0.0em;
	padding-left:10px;
	padding-bottom: 10px;
}

.token_block_header_left {
	float:left;
	text-align: left;
	font-size:1.0em;
	letter-spacing: -0.0em;
	padding-left:10px;
	padding-bottom: 10px;
	width:calc(50% - 10px);
}

.token_block_header_right {
	float:right;
	text-align: right;
	font-size:1.0em;
	letter-spacing: -0.0em;
	padding-right:10px;
	padding-bottom: 10px;
	width:calc(50% - 10px);
}
.selected_detail_link {
	color:#FFF;
	cursor: pointer;
	text-decoration: underline;
}
.detail_link {
	cursor: pointer;
	text-decoration: underline;
}
.token_block_header_left.opera {
	color:var(--tokenPageColorsOpera3);
}
.token_block_header_left.opera_gm {
	color:var(--tokenPageColorsOperaGM3);
}
.token_block_header_left.white {
	color:var(--tokenPageColorsWhite3);
}
.token_block_header_left.black {
	color:var(--tokenPageColorsBlack3);
}
.token_block_header_left.green {
	color:#7ea9ff;
}
.token_block_header_right.opera {
	color:var(--tokenPageColorsOpera3);
}
.token_block_header_right.opera_gm {
	color:var(--tokenPageColorsOperaGM3);
}
.token_block_header_right.white {
	color:var(--tokenPageColorsWhite3);
}
.token_block_header_right.black {
	color:var(--tokenPageColorsBlack3);
}
.token_block_header_right.green {
	color:#7ea9ff;
}

.token_block_header.opera {
	color:var(--tokenPageColorsOpera3);
}
.token_block_header.opera_gm {
	color:var(--tokenPageColorsOperaGM3);
}
.token_block_header.white {
	color:var(--tokenPageColorsWhite3);
}
.token_block_header.black {
	color:var(--tokenPageColorsBlack3);
}
.token_block_header.green {
	color:#7ea9ff;
}
.block_details {
	
	
	font-size:1.0em;
	letter-spacing: -0.0em;
}
/*.block_details_description {
	font-size:0.9em;
	letter-spacing: -0.0em;
}*/
.token_block_opera .block_details a, .token_block_opera .block_details a:hover {
	color:#FFF;
	text-decoration: underline;
}
.token_block_opera_white .block_details a, .token_block_opera_white .block_details a:hover {
	color:#000;
	text-decoration: underline;
}
.token_block_opera_black .block_details a, .token_block_opera_black .block_details a:hover {
	color:#FFF;
	text-decoration: underline;
}
.token_block_opera_green .block_details a, .token_block_opera_green .block_details a:hover {
	color:#7ea9ff;
	text-decoration: underline;
}
#accept_counter_offer_link {
	cursor: pointer;
}
#cancel_counter_offer_link {
	cursor: pointer;
}
#cancel_counter_offer_link a, #cancel_counter_offer_link a:hover, #accept_counter_offer_link a, #accept_counter_offer_link a:hover {
	text-decoration: underline;
	color:#FFF;
}
.token_logs_line {
	font-size:0.9em;
}
.counter_offer_0, .counter_offer_1, .token_logs_line {
	padding:6px 4px;
}

.counter_offer_1 {

}
.counter_offer_0.black {
	background-color:var(--tokenPageColorsBlack2);
}
.counter_offer_0.green {
	background-color:#040b01;
}
.counter_offer_0.white {
	background-color:var(--tokenPageColorsWhite2);
}
.counter_offer_0.opera {
	background-color:var(--tokenPageColorsOpera2);
}
.counter_offer_0.opera_gm {
	background-color:var(--tokenPageColorsOperaGM2);
}
.token_logs_line a, .token_logs_line a:hover {
	text-decoration: underline;
	color:#FFF;
}

.counter_offer_0.green a, .counter_offer_0.green a:hover {
	color:#7ea9ff;
}
.counter_offer_1.green a, .counter_offer_1.green a:hover {
	color:#7ea9ff;
}

.wide_100 {
	width:100%;
/*	font-size:1.4em;
	font-weight: bold;
	color:#000;*/
}

@media (max-width: 660px) {
	.token_block_background {
		width:380px;
	}
	.token_block_header {
		width: 360px;
	}
	.token_block {
		width: 380px;
		padding:10px;
	}
}

.modal_text {
	font-size:16px;
	text-transform: uppercase;
}

/* GRID FANTUM */

.gridFantum {
	vertical-align: top;
	display:inline-block;
}
.gridFantumYours {
	background-color:  rgba(255,255,255,0.05);
	border-radius: 10px;
}
#fantumHolderBrowse {
/*	width:calc(100% - 30px);*/
	margin:0 auto;
	text-align:center;
}
.numberFloatBrowse {
	float:left;
	width:20px;
	height:20px;
	margin-top:2px;
	padding-left: 6px;
/*	margin-right: -20px;*/
}
.blue_page .numberFloatBrowse {
	color: #dadada;
}
.blue_page .pagination {
	color: #FFF;
}
.blue_page p {
	color: #FFF;
}
.blue_page .grid_title_line_one {
	color: #FFF;
}
.blue_page .grid_title_line_two {
	color: #FFF;
}
.blue_page .MuiInputBase-root {
	color: #FFF;
}
.theFantum {
	margin-top:10px;
	image-rendering: pixelated;

}
.theFantumTint {
	position: absolute;
	margin-left: 8px;
	margin-top:22px;
	mix-blend-mode: screen;
	image-rendering: pixelated;

}
.tintedFantumCanvas {
	width: 100px;
	height: 100px;
}
.theFantumName a, .theFantumName a:hover {
	text-decoration: none;
}
.theFantumName {
	padding:6px;
	width:100%;
	color:#000;
	line-height: 1.1em;

	
}
.fantumRaritySmaller {
	margin-top:5px;
	font-size:0.8em;
	width:100%;
}
.fantumRaritySmallerTwo {
	width:100%;
	margin-top:-2px;
	font-size:0.8em;
}
.fantumRaritySmallerThree {
	width:100%;
	margin-top:-2px;
	font-size:0.8em;
	color: #097411;
}
#gridFantumHolder {
/*	background-image: url('./img/find.png');
	background-position: top center;*/
	width:100%;
	max-width:670px;
	min-height:600px;
	background-repeat:no-repeat;
	
	margin:0px auto 0px auto;
	background-color:#fafafa;

}

.doc_container {
	width:640px;
	margin: 0 auto;
}
@media only screen and (max-width: 600px) {
  .doc_container {
		
		width:320px;
	}
}


.gridHeader {
	width: 620px;
	margin: 0 auto 4px auto;
}

@media (max-width: 660px) {
	.gridHeader {
		width: 340px;
	}
}

.gridHeaderLeft {
	width:30%;
	vertical-align: top;


	color:#000;
	font-size: 2.2em;
	margin:-4px 0px 0px 0px;
	text-align: left;
}
.gridHeaderRight {
	width:70%;
	vertical-align: top;
}

form {
	display:inline-block;
	width:100%;
}

.dropdown_sort {
	display:inline-block;
	vertical-align: top;
	width:44px;
}
.dropdown_rpp {
	display:inline-block;
	vertical-align: top;
	width:48px;
}
.dropdown-item {
	font-size:14px;
}
.grid_title_line_one {
	margin-top:-8px;
	line-height: 1.0em;
}
.grid_title_line_two {
	margin-top:-2px;
	font-size:14px;
}
.numberForSale {
	border-radius: 1px;
	float:right;
	background-color: var(--primaryColor);
	padding:1px 4px 0px 4px;
	font-size:0.9em;
	color:#FFF;
	cursor:pointer;
	margin-bottom:-4px;
	margin-top:-3px;
}
.numberForSale a, .numberForSale:hover {
	color:#FFF;
	text-decoration: none;
}
.dropdown-menu {
	min-width: 0px;
}


.history_line {
	font-size:1.1em;
	margin:6px auto;
}

.history_line, .history_line a, .history_line a:hover {
	color:#000;
}

.history_bought, .history_bought a, .history_bought a:hover {
	color:#00ac18;
}
.history_sold, .history_sold a, .history_sold a:hover {
	color:#ac0000;
}

.menu_item:hover {
	text-decoration: underline;
	color:#FFF;
}

.menu_item {
	text-decoration: none;
	color:#FFF;
	display:inline-block;
	font-size:1.6em;
	vertical-align: middle;

}
.menu_img_a {
	width:28px;
	height:28px;
	margin:-2px 16px 0px 0px;
}
.menu_img_ant {
	width:28px;
	height:28px;
	margin:-2px 20px 0px 20px;
}
.menu_img_w {
	width:28px;
	height:28px;
	margin:-2px -6px 0px -6px;
/*	background-image: url('./img/icons/wallet.png');*/
	background-size:contain;
	display:inline-block;
	vertical-align: middle;
	/*filter: drop-shadow(1px 1px 1px #000);*/
}


/* hide mobile version by default */
.mobile_m {
	display: none;
}
.menu_dash {
	padding:0px 8px;
	display:inline-block;
}
.menu_cont_inner {
	margin:0 auto;

}
/* when screen is less than 600px wide
 show mobile version and hide desktop */
@media (max-width: 660px) {
	.mobile_m {
	  display: inline-block;
	}
	.desktop_m {
	  display: none;
	}
	.menu_dash {
		padding:0px 14px;
	}
	.menu_cont_inner {
		
	}
}



.menu_item_cont {
	display:inline-block;
}

.menu_img_mint {
	background-image: url('./img/icons/mint.png');
}
.menu_img_browse {
	background-image: url('./img/icons/browse.png');
}
.menu_img_market {
	background-image: url('./img/icons/market.png');
}
.menu_img_wallet {
	background-image: url('./img/icons/wallet.png');
}


.attribute_img_ {
	
	background-color:#e9e9e9;
}
.attribute_img_0 {
	background-color:#e9cee0;
}
.attribute_img_1 {
	background-color:#ced9e9;
}

.attribute_img_, .attribute_img_0, .attribute_img_1 {

}


.attribute {

	font-size:10px;
	display:inline-block;
	width:160px;
	min-height:160px;
	margin-top:20px;
	vertical-align: bottom;
}



.myCountA, .myCountA:hover {
	color: #000;
}

.countGreen {
	color:green;
}

.countRed {
	color:#ac0000;
}
.countWhite {
	color:#FFF;
}
.countGreen a, .countGreen a:hover {
	color:green;
	cursor: pointer;
}

.countRed a, .countRed a:hover {
	color:red;
	cursor: pointer;
}
.countWhite a, .countWhite a:hover {
	color:#FFF;
	cursor: pointer;
}





.duel_button {
	margin: 0 auto 40px auto;
	color: #FFF;
	font-size: 20px;
	width: 70%;
}
.button_list {
		list-style: none;
	list-style-type: none;
	margin-left: -70px;
}


#left_fighter_holder, #right_fighter_holder {
	height: 330px;
/*		overflow: hidden;*/
}

#left_fighter_holder {

	vertical-align: top;
	
	width: 40%;
	display: inline-block;

}
.move_question_mark_up {
	margin-top: -48px;
}
.move_duel_left_back {
	margin-top: -48px;
}

.token_modal_button_pad_right {
	margin-right: 10px;
	margin-bottom: 10px;
}

.left_flip {
	-webkit-transform: scaleX(-1);
  transform: scaleX(-1);
}

#right_fighter_holder {
	/*overflow: hidden;*/
	vertical-align: top;
	/*margin-top: -48px;*/
	width: 40%;
}
.inline-block {
	display: inline-block;
}

.underfight_text {
	font-size: 16px;
	width: calc(100% - 20px);
	margin: 0 auto;
}


.selectFighterFantum {
	width: 100%;
	margin: 0px auto 6px auto;
	cursor: pointer;
	padding: 0px 20px;
}
.selectFighterFantum:hover {
	background-color: #f5f5f5;
	border-left: solid 10px #f5f5f5;
	border-right: solid 10px #f5f5f5;
	width: calc(100% + 37px);
	padding-right: 27px;
	margin-left: -20px;
	padding-left: 30px;

}




.fighterSelectModal {
	min-height: 210px;
}
.select_fantum_image_left {
	vertical-align: top;
	width: 50px;
	float: left;
	text-align: left;
	padding-left: 0px;
	padding-top: 2px;
	padding-bottom: 0px;
	height: 60px;
	image-rendering: pixelated;
}
.select_gothum_image_left {
	vertical-align: top;
	width: 50px;
	float: left;
	text-align: left;
	padding-left: 0px;
	padding-top: 2px;
	padding-bottom: 0px;
	height: 60px;
	image-rendering: pixelated;
}
.select_fantum_text_middle {
	vertical-align: middle;
	width: calc(75% - 60px);
	display: inline-block;
	text-align: right;
	padding-right: 10px;
	padding-top: 5px;
	padding-bottom: 5px;
	font-size: 16px;
	line-height: 1.4em;
	height: 60px;
}
.select_fantum_text_stats {
	font-size: 12px;
}
.select_fantum_level_right {
	float: right;
	margin-top: 14px;
	/*width: calc(75% - 60px);*/
	text-align: right;
	padding: 1px 6px 2px 6px;
	background-color: #097411;
	margin-right: 0px;
}
.select_gothum_text_middle {
	vertical-align: middle;
	width: calc(75% - 60px);
	display: inline-block;
	text-align: right;
	padding-right: 10px;
	padding-top: 5px;
	padding-bottom: 5px;
	font-size: 16px;
	line-height: 1.4em;
	height: 60px;
}
.select_gothum_level_right {
	float: right;
	margin-top: 10px;
	width: 50px;
	text-align: right;
	margin-right: 0px;
}

@media (max-width: 660px) {
	.selectFighterFantum {
		width: calc(100% + 40px);
		margin-left: -20px;
		padding: 0px 0px;
	}
	.selectFighterFantum:hover {
		background-color: #f5f5f5;
		border-left: solid 10px #f5f5f5;
		border-right: solid 10px #f5f5f5;
		width: calc(100% + 117px);
		padding-right: 7px;
		margin-left: -60px;
		padding-left: 10px;

	}

/*	#left_fighter_holder {
		margin-left: -180px;
	}
	#right_fighter_holder {
		margin-right: -180px;
	}*/
}


.duel_choose_fum {

	margin-bottom: 10px;
}
.duel_approve_fum {

	margin-bottom: 10px;
}
.duel_approve_foo {

	margin-bottom: 10px;
}
.levelBox {
	float: right;
	font-size: 18px;
	text-align: center;
	margin-top: 2px;
	background-color: #097411;
	padding: 2px 8px 4px 7px;
	font-weight: bold;
	border-radius: 0px;
	text-shadow: 2px 2px 0px #000000;
	margin-bottom: 0px;
}
.nameBox {
	
	text-align: center;
	font-weight: bold;
	font-size: 20px;
	float: left;
	margin-top: 0px;
	line-height: 1.0em;
	margin-bottom: 10px;
	margin-right: 6px;
}
@media (max-width: 660px) {
	.nameBox {
		font-size: 14px;
	}
	.levelBox {
		font-size: 13px;
	}
}

.nameBox a, .nameBox a:hover {
	color: #FFF;
	cursor: pointer;
}
.select_fum_level_color {
	color: #FFF;
}

.fight_img {
	width: 100%;
	max-width: 260px;
	image-rendering: pixelated;

	margin: 0 auto;
	background-position: center center;
	background-repeat: no-repeat;
	background-size: contain;

}
.fight_img_inner {
	width: 100%;
	max-width: 260px;
	image-rendering: pixelated;

	background-position: center center;
	background-repeat: no-repeat;
	background-size: contain;

}



.fighterBox {

	padding-top: 4px;
	width: 100%;
	height: 310px;
}

.tombstones {

}
#clip_width {
	overflow: hidden;
	width: 100%;
	
}
@media (max-width: 540px) {
	#clip_width {
		height: 260px;
	}
}
.matchNum, .matchNum a, .matchNum a:hover {
	font-size: 16px;
	color: #FFF;
}
.lb_under {
	margin-top: 10px;
}

#lTombMask, #rTombMask {
	padding-top: 0px;
	height: 295px;
	/*border-bottom: solid 10px green;*/
}
@media (max-width: 660px) {
	#lTombMask, #rTombMask {
		height: 290px;
	}
}
@media (max-width: 460px) {
	#lTombMask, #rTombMask {
		height: 220px;
	}
}
@media (max-width: 360px) {
	#lTombMask, #rTombMask {
		height: 180px;
	}
}


#lTombMask {
	
	vertical-align: top;
	overflow: hidden;
	width: 100%;
}

#rTombMask {
	vertical-align: top;
	overflow: hidden;
	width: 100%;
}

#duel_title_holder {
	height: 120px;
	width: calc(100% - 40px);
	margin: 0 auto;
}
#duel_get_ready {
	font-size: 50px;
	width: 100%;
	padding-top: 40px;
	position: absolute;
	text-transform: capitalize;
	text-shadow: 2px 2px 0px #000000;
	z-index: 10;
}
@media (max-width: 480px) {
	#duel_get_ready {
		font-size: 30px;
	}
}
#duel_countdown {
	width: 100%;
	padding-top: 120px;
	position: absolute;
	font-size: 200px;
	text-shadow: 2px 2px 0px #000000;
	z-index: 10;
}
#duel_get_ready_and_countdown {
	position: absolute;
	width: 100%;
	height: 1200px;
	overflow: hidden;
}
.minusOneZ {
	z-index: -1;
}
.duel_name_level_holder {
	height: 34px;
	margin: 4px auto 0px auto;
	display: inline-block;
	vertical-align: top;
}
.duel_wait {
	font-size: 14px;

}
.viewDeathFantum {
	margin-top: -2px;
	font-size:0.8em;
}
.fantumLevel {
	margin-top: 0px;
	font-size:0.8em;
}
.levelColor {
	display: inline-block;
	color: #FFF;
	background-color: #092c74;
	margin-top:10px;
	font-size:0.9em;
	padding: 2px 4px;
	float: right;
}
.fantumLevel.isGy {
	color: #FFF;
}
#versus {
	width: 100%;
	font-size: 40px;
	position: absolute;
	top: 460px;
	font-weight: bold;
	font-style: italic;
	color: #FFF;
	text-shadow: 4px 4px 0px #000000;
	z-index: 0;
}
@media (max-width: 660px) {
	#versus {
		top: 400px;
		font-size: 20px;
	}
}
.match_history {
	font-size: 16px;
}


.history_page_link {
	margin-top: 10px;
	color:#FFF;
	font-size:1.1em;
	height: 30px;
}
.history_page_link_black {
	margin-top: 10px;
	color:#000;
	font-size:1.1em;
	height: 30px;
}
.history_page_link a, .history_page_link a:hover {
	text-decoration: none;
	color:#FFF;
}
.history_page_link_black a, .history_page_link_black a:hover {
	text-decoration: none;
	color:#000;
}
#fooBurnedCounter {
	display: inline-block;
	margin: 0 auto;
	font-size: 26px;
	color: #363434;
	padding: 76px 12px 6px 12px;
	line-height: 1.0em;
	background-image: url('./img/rip_only.png');
	background-repeat: no-repeat;
	height: 200px;
	width: 220px;
	background-size: contain;
	background-position: center center;
	border-bottom: solid 10px #002e17;
}
.fooBurnedText {
	line-height: 1.0em;
	font-size: 12px;
}
.level_bar_inner {
	background-color: #097411;
	border-radius: 6px;
	height: 19px;
}
.level_bar_level {
	position: absolute;
	padding-left: 10px;
}
.token_block_opera_border {
	border:  solid 1px var(--tokenPageColorsOpera1);
}
.token_block_opera_gm_border {
	border:  solid 1px var(--tokenPageColorsOperaGM1);
}
.token_block_opera_border_white {
	border:  solid 1px var(--tokenPageColorsWhite1);
}
.token_block_opera_border_black {
	border:  solid 1px var(--tokenPageColorsBlack1);
}
.token_block_opera_border_green {
	border:  solid 1px #030901;
}
.level_bar {
	display: block;
	margin-top: 6px;
	margin-bottom: 10px;
	
	/*height: 16px;*/
	color: #FFF;
	font-size: 12px;
	padding-bottom: 0px;
	padding-top: 0px;
	border-radius: 6px;
}
.level_bar_of {
	width: 480px;
	font-size: 20px;
	margin: 0 auto;
}
.level_bar_duel_xp {
	position: absolute;
	width: 480px;
	padding: 4px 16px 4px 0px;
	font-size: 16px;
	text-align: right;
}
.level_bar_duel_level {
	position: absolute;
	width: 480px;
	padding: 4px 0px 4px 16px;
	font-size: 16px;
	text-align: left;
}
.level_bar_inner_duel {
	background-color: #097411;
/*	width: 100%;*/
	border-radius: 16px;
	height: 34px;
	padding: 4px 0px 4px 16px;
	font-size: 16px;
	text-align: left;
}
.level_bar_duel {
	overflow: hidden;
	display: block;
	width: 480px;
	margin:  6px auto 10px auto;
	/*height: 16px;*/
	color: #FFF;
	font-size: 12px;
	padding-bottom: 0px;
	padding-top: 0px;
	border-radius: 16px;
	background-color: #180e0e;
}

@media (max-width: 640px) {
	.level_bar_duel {
		width: 100%;
	}
	.level_bar_inner_duel {
		width: 100%;
	}
	.level_bar_duel_xp {
		width: calc(90%);
	}
	.level_bar_of {
		width: 100%;
	}
	.level_bar_duel_level {
		width: 90%;
	}
}



.grid_level_box {
	color: #097411;
	font-size: 12px;
	padding: 0px 4px 0px 4px;
	/*background-color: #097411;*/
}

.leaderboard_fantum {
	width: 100%;
	margin: 0px 0px 6px 0px;
	cursor: pointer;
	padding: 0px 20px;
	border-bottom: solid 1px black;
}
.leaderboard_fantum:hover {
	background-color: #221818;
}
.leader_fantum_rank {
	color: #FFF;
	vertical-align: middle;
	width: 20px;
	display: inline-block;
		float: left;
		font-weight: bold;
		font-size: 20px;
		padding-top: 15px;
		margin-right: 16px;
}
.leader_fantum_image_left, .leader_fantum_text_middle {
	height: 70px;
}
.leader_fantum_image_left {
	vertical-align: top;
	float: left;
	text-align: left;
	padding-left: 0px;
	padding-top: 0px;
	padding-bottom: 0px;
	display: inline-block;
	image-rendering: pixelated;
}
.leader_fantum_text_middle {
	vertical-align: middle;
	width: calc(100% - 164px);
	display: inline-block;
	text-align: right;
	padding-right: 10px;
	padding-top: 8px;
	padding-bottom: 5px;
	font-size: 16px;
	line-height: 1.1em;
	
}
.leader_fantum_text_stats {
	font-size: 12px;
}
.leader_fantum_level_right {
	float: right;
	margin-top: 19px;
	text-align: center;
	padding: 1px 6px 2px 6px;
	background-color: #097411;
	margin-right: 0px;
	font-size: 18px;
	width: 45px;
/*	letter-spacing: 0.01em;*/
}
.leader_container {
	width:480px;
	margin: 0 auto;
}
@media (max-width: 680px) {
	.leader_container {
		width:100%;
	}
	.leaderboard_fantum {
		padding: 0px 20px;
	}
	.leader_fantum_level_right {
		font-size: 14px;
	}
	.leader_fantum_text_middle {
		width: calc(100% - 164px);
		
	}
}
.leader_darker_num {
	color: #979797;
}

.match_container {
	width: 100%;
	margin-bottom: 20px;
}
/*.match_container a, .match_container a:hover {
	color: #FFF;
}*/
.match_name a, .match_name a:hover {
	color: #FFF;
}
.match_left_fum {
	width: 30%;
	display: inline-block;
}
.match_vs_fum {
	vertical-align: middle;
	display: inline-block;
	width: 40%;
	line-height: 1.4em;
}
.match_right_fum {
	width: 30%;
	display: inline-block;
}


.leaders_page_controls {
	font-size: 16px;
}
.leaders_page_controls a, .leaders_page_controls a:hover {
	color: #FFF;
}


.duel_history_page_controls {
	font-size: 16px;
}
.duel_history_page_controls a, .duel_history_page_controls a:hover {
	color: #FFF;
}

.dark_footer_box {
	width: 100%;
	padding: 10px 0px;
	background-color: #271e1c;
	font-size: 13px;
}
.switch_footer_box {
	width: 100%;
	padding: 10px 0px;
	background-color: #271e1c;
	font-size: 13px;
	color: #FFF;
}

.dark_footer_box a, .dark_footer_box a:hover {
	color: #FFF;
}
.dark_footer_box a:hover {
	text-decoration: underline;
}

.footer_holder_holder {
	width: 100%;
	background-color: #251c1a;
}

.footer_holder {
	padding: 10px 0px;
	width: 480px;
	margin: 0 auto;
	color: #FFF;
	font-size: 14px;
}
@media (max-width: 480px) {
	.dark_footer_box {
		font-size: 11px;
	}
	.footer_holder {
		width: 100%;
	}
}

.footer_block {
	display: inline-block;
	vertical-align: top;
	width: 33%;
}
.footer_block_half {
	display: inline-block;
	vertical-align: top;
	width: 50%;
}


.footer_block a, .footer_block a:hover {
	color: #FFF;
}
.footer_block a:hover {
	color: #FFF;
	text-decoration: underline;
}

.footer_darkbox_icon {
	margin-top: -4px;
	margin-right: 6px;
}


.footer_block_icon {
	margin-top: -4px;
	margin-right: 2px;
}

#header_contains_tokens {
	display: inline-block;
	vertical-align: middle;
	margin-top: -2px;
}
.header_token_icon {
	margin-top: -3px;
	border: solid 1px #343434;
	margin-left: 4px;
	border-radius: 2px;
}
.ripBal {
	cursor: pointer;
	font-size: 1.2em;
	color:#FFF;
	margin-top:0px;
	margin-right:8px;
	vertical-align: top;
}

.fooBal {
	cursor: pointer;
	font-size: 1.2em;
	color:#FFF;
	margin-top:0px;
	margin-right:8px;
	display:inline-block;
	vertical-align: top;
}
.match_our_fighter {

}
.match_their_fighter {

}

.trade_event_row {
	width: 640px;
	height: 80px;
	margin: 0 auto;
	margin-bottom: 10px;

}

.trade_event_fantum_image {
	vertical-align: middle;
	display: inline-block;
}
.trade_event_text {
	vertical-align: middle;
	display: inline-block;
	width: 50%;
	line-height: 1.2em;
	padding: 0px 20px;
}
.trade_event_text a, .trade_event_text a:hover {
	color: #000;
}
.trade_event_price {
	vertical-align: middle;
	display: inline-block;
	font-size: 2.0em;
	margin-left: 10px;
}
.trade_event_ftm {
	margin-top: -4px;
	line-height: 1.2em;
	font-size: 10px;
}

@media (max-width: 640px) {
	.trade_event_row {
		width: 100%;
	}
}

.trade_history_page_controls {
	font-size: 16px;
	color: #000;
}
.trade_history_page_controls a, .trade_history_page_controls a:hover {
	color: #000;
}
.dueling_is_here {
	font-size: 30px;
	letter-spacing: 0.3em;
	text-shadow: 2px 2px 0px rgba(255,255,255,0.5);;
	font-weight: bold;
}
.about_large_heading {
	font-size: 30px;
	letter-spacing: 0.3em;
	text-shadow: 2px 2px 0px rgba(255,255,255,0.5);;
	font-weight: bold;
}
.dueling_subheading {
	font-size: 20px;
	letter-spacing: 0.3em;
	text-shadow: 2px 2px 0px rgba(255,255,255,0.5);;
	font-weight: bold;
}
.duels_splash_center_container {
	padding: 0px 20px;
}
.duels_splash_center_container a, duels_splash_center_container a:hover {
	color: #FFF;
	text-decoration: underline;

}
.start_dueling_now {
	font-size: 24px;
}
.center_an_img {
	text-align: center;
	width: 640px;
	margin: 0 auto;
}
@media (max-width: 640px) {
	.center_an_img {
		width: 100%;
	}
	.center_an_img img {
		max-width: 100%;
	}
	.duels_splash_center_container {
		width: 100%;
	}
}
.white_link a, .white_link a:hover {
	color: #FFF;
}
#contains_match_snaps {
	margin: 0 auto;
	width: 100%;
	height: 320px;
}
.contains_match_snaps_inner {
	width: 450px;
	height: 320px;
}

#snap_match_text {
	color: #FFF;
	font-size: 20px;
	font-weight: bold;
	width: 450px;
	margin-top: 20px;
	line-height: 1.2em;
}
.snap_result_text {
	font-style: italic;
	font-size: 14px;
}
.snap_loser {
	position: absolute;
	z-index: 5;
	width: 160px;
	height: 160px;
	left: 0px;
	top: 140px;
}
.snap_winner {
	position: absolute;
	z-index: 10;
	left: 130px;
	top: 40px;
	width: 320px;
	height: 320px;
}
.nudge_winner_if_tie {
	top: 140px;
	left: 250px;
	width: 160px;
}
.nudge_loser_if_tie {
	left: 40px;
	width: 160px;
}
.theFantumTintSnapMatch {
	position: absolute;
	margin-left: 0px;
	margin-top:0px;
	mix-blend-mode: screen;
}
.redXSnapMatch {
	position: absolute;
	margin-left: 0px;
	margin-top: 0px;
	z-index: 12;
}
.equalsSnapMatch {
	position: absolute;
	margin-left: 195px;
	margin-top: 194px;
	z-index: 12;
}
.winner_snap_name {
	font-weight: bold;
/*	color: #FFF;*/
	position: absolute;
	z-index: 12;
	width: 320px;
	height: 30px;
	left: 130px;
	font-size: 20px;
	text-shadow: 2px 2px 0px rgba(0,0,0,1.0);
}
.winner_snap_level_box {
	font-weight: bold;
	color: #FFF;
	display: inline-block;
	z-index: 12;
	margin-left: 10px;
	font-size: 20px;
	padding: 0px 8px 2px 8px;
	text-shadow: 2px 2px 0px rgba(0,0,0,1.0);
	background-color: #097411;
}
.loser_snap_name {
	font-weight: bold;
/*	color: #FFF;*/
	position: absolute;
	z-index: 12;
	width: 160px;
	height: 20px;
	top: 120px;
	left: 0px;
	font-size: 10px;
	text-shadow: 2px 2px 0px rgba(0,0,0,1.0);
}
.loser_snap_level_box {
	font-weight: bold;
	color: #FFF;
	display: inline-block;
	z-index: 12;
	margin-left: 10px;
	font-size: 10px;
	padding: 0px 6px 2px 4px;
	text-shadow: 2px 2px 0px rgba(0,0,0,1.0);
	background-color: #097411;
}
.winner_snap_name_equals {
	font-weight: bold;
	/*color: #FFF;*/
	position: absolute;
	z-index: 12;
	width: 160px;
	height: 20px;
	top: 120px;
	left: 250px;
	font-size: 10px;
	text-shadow: 2px 2px 0px rgba(0,0,0,1.0);
}
.winner_snap_level_box_equals {
	font-weight: bold;
	color: #FFF;
	display: inline-block;
	z-index: 12;
	margin-left: 10px;
	font-size: 10px;
	padding: 0px 6px 2px 4px;
	text-shadow: 2px 2px 0px rgba(0,0,0,1.0);
	background-color: #097411;
}
.loser_snap_name_equals {
	font-weight: bold;
	/*color: #FFF;*/
	position: absolute;
	z-index: 12;
	width: 160px;
	height: 20px;
	top: 120px;
	left: 40px;
	font-size: 10px;
	text-shadow: 2px 2px 0px rgba(0,0,0,1.0);
}
.loser_snap_level_box_equals {
	font-weight: bold;
	color: #FFF;
	display: inline-block;
	z-index: 12;
	margin-left: 10px;
	font-size: 10px;
	padding: 0px 6px 2px 4px;
	text-shadow: 2px 2px 0px rgba(0,0,0,1.0);
	background-color: #097411;
}
.nudge_tie_all_up {
	margin-top: -100px;
}
.x_on_the_loser_match {
	position: absolute;
}
.x_on_the_loser_match img {
	width: 60px;
	height: 60px;
	margin-left: 40px;
}
.tintFighter {
	width: 100%;
	max-width: 260px;
	image-rendering: pixelated;
}
.animatedGif {
	width: 100%;
	max-width: 260px;
	image-rendering: pixelated;
}
.smokeOff {
	display: none;
}

@media (max-width: 640px) {
	/*.animatedGif {
		margin-left: -70px;
		margin-top: 0px;
	}*/
}

.fighter_queue {
	position: absolute;
	width: 320px;
	padding: 20px;
	background-color: rgba(34,24,24,0.75);
	border-radius: 20px;
	font-size: 11px;
}
.fighter_queue a, .fighter_queue a:hover {
	color: #FFF;
}

@media (max-width: 640px) {
	#fighter_queue {
		margin-top: -280px;
		font-size: 9px;
	}
	.hide_if_mobile {
		display:  none;
	}
}

#offensive_spell {
	width: 320px;
	height: 320px;
	
	
}
.gridFantumPad {
	padding-top: 10px;
	padding-left: 2px;
}
.offensive_top_offset_candelabra {
	margin-top:  -30px;
}
.offensive_top_offset_mask {
	margin-top:  -30px;
}
.offensive_top_offset_lantern {
	margin-top:  -30px;
}
.offensive_top_offset_torch {
	margin-top:  -30px;
}
.offensive_top_offset_sword {
	margin-top:  -20px;
}
.offensive_top_offset_flower {
	margin-top:  40px;
}

.offensive_gif {
	width: 320px;

}
.cursor_pointer {
	cursor: pointer;
}
.duellerNumBox {
	position: absolute;
	font-size: 10px;
	font-weight: bold;
	margin-top: 18px;
	margin-left: -55px;
	width: 40px;
	text-align: right;
}
.rm_class_choose_button {
	margin-bottom: 10px;
	margin-left: 5px;
	margin-right: 5px;
}
.stakeSliderHolder {
	width: calc(640px - 0px);
/*	padding: 0px 20px;*/
	margin: 0 auto;
}
@media (max-width: 640px) {
	.stakeSliderHolder {
		width: calc(100% - 40px);
		margin: 0 20px;
	}
}
.stakeReceiveLeft {
	width: calc(50% - 8px);
	padding: 4px;
	display: inline-block;
}
.stakeReceiveRight {
	width: calc(50% - 8px);
	padding: 4px;
	display: inline-block;
}
.stakeReceiveRightNoBlock {
	width: calc(50% - 8px);
	padding: 4px;
}

.bigStakeNumbersWhite {
	/*text-shadow: 2px 2px 0px rgba(0,0,0, 1.0);*/
	color: #000;
	font-size: 24px;
	line-height: 1.2em;
	font-weight: bold;
	margin-bottom: -0px;
}

.bigStakeNumbers {
	/*text-shadow: 2px 2px 0px rgba(0,0,0, 1.0);*/
	color: var(--danger);
	font-size: 24px;
	line-height: 1.2em;
	font-weight: bold;
	margin-bottom: -0px;
}
.stakeSliderHolder p {
	font-size: 16px;
}
@media (max-width: 640px) {
	.bigStakeNumbersWhite {
		font-size: 16px;
	}
	.stakeSliderHolder p {
	font-size: 14px;
	}
}

.stakePageLink a, .stakePageLink a:hover {
	color: #000;
}

.switch_logo {
	cursor: pointer;
	margin: 10px 20px;
}

.mint_note {
	color: #FFF;
}
.tot_solid {
	background-color: #191919;
	background-repeat: no-repeat;
}
#gum_track {
	width: 100%;
	height: 300px;
	margin: 0px auto 40px auto;
}
.pickADoor {
	font-size: 34px;
	color: #FFF;
	font-weight: bold;
}
.pickADoor2 {
	font-size: 20px;
	color: #FFF;
}
.pickADoor3 {
	font-size: 16px;
	color: #FFF;
	font-style: italic;
}
@media (max-width: 840px) {
	#gum_track {
		margin: 340px auto 40px auto;
	}
}
.gum_holder {

}
#doors {
	width: 100%;
	height: 400px;
	margin: 0px auto 0px auto;
	padding-top: 60px;


}
.doors {
	background-repeat: no-repeat;
	background-size: 159px 276px;
	background-position: center;
	width: 159px;
	height: 276px;
	display: inline-block;
	margin: 20px;
	box-sizing: border-box;
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
	border: solid 10px #000;
}
.doors:hover {
	border: solid 10px #FFF;
	cursor: pointer;
}
.gum_tot_name {
	color: #FFF;
	font-size: 20px;
	margin-bottom: 10px;
}
.gum_tot_img {
	margin-bottom: 20px;
}
.doorOpen {
	background-image: url('./img/tot/DoorOpen.png');
}
.doorGrey {
	background-image: url('./img/tot/GreyDoor.png');
}
.doorRed {
	background-image: url('./img/tot/RedDoor.png');
}
.doorGreen {
	background-image: url('./img/tot/GreenDoor.png');
}
.doorOrange {
	background-image: url('./img/tot/OrangeDoor.png');
}
.doorPurple {
	background-image: url('./img/tot/PurpleDoor.png');
}
.tot_result {
	position: absolute;
	color: #FFF;
	width:calc(159px - 20px);
	text-align: center;
	padding-top: 60px;
	font-weight: bold;
	font-size: 16px;
}
.bag_holder {
	margin:  0 auto;
	width: 500px;
}
@media (max-width: 500px) {
	.bag_holder {
		width: 100%;
	}
}

.recipe_container {
	width: 640px;
	margin: 0px auto 20px auto;
}
@media (max-width: 640px) {
	.recipe_container {
		width: 100%;
	}
}
.recipe_line {
	color: #FFF;
	background-color: #271e1c;
	margin: 4px 0px;
	padding-top: 4px;
	padding-left: 4px;
	height: calc(48px + 8px);
}
.recipe_line:hover {
	background-color: #FFF;
	color: #000;
	cursor: pointer;

}

.item_box_one_liner {
	margin: 6px 10px 6px 6px;
	color: #FFF;
	text-align: right;
	font-size: 10px;
	width: 48px;
	height: 48px;
	background-size: cover;
	display: inline-block;
	image-rendering: pixelated;
	vertical-align: middle;
}

.recipe_line_left_box {
	width: 48px;
	padding: 0px;
	display: inline-block;
	vertical-align: top;
}
.recipe_line_right_box {
	font-size: 20px;
	width: calc(100% - 52px);
	display: inline-block;
	text-align: center;
	padding: 8px 0px 0px 0px;
	vertical-align: top;
}

.recipe_dialog {
	position: absolute;
	padding: 12px;
	margin-top: 8px;
/*	top: 0px;
	left: 0px;*/
	width: 100%;
	/*height: 984px;*/
	background-color: #211918;
}
.inner_recipe_dialog {
	margin:  0 auto;
	width: 640px;
	min-height: 1005px;
}
@media (max-width: 640px) {
	.inner_recipe_dialog {
		width:  100%;
	}
}
.close_box_recipe_dialog {
	/*position: absolute;
	right: 0px;
	top: 0px;*/
	width: 60px;
	height: 60px;
	margin: 10px 4px -60px 4px;
/*	background-color: white;*/
	cursor: pointer;
}
.requires_container {
	width: 100%;
	margin: 20px 0px;
}

.ingredient_box {
	display: inline-block;
	width: 160px;
}
.icon_tint_fade {
	opacity: 0.85;
}
.icon_tint_fadeno {
	opacity: 0.0;
}
.text_tint_fade {
	color: #ab5a5a;
}
.text_tint_fadeno {
	color: #FFF;
}
.item_input_box {
	margin: 4px;
	color: #FFF;
	text-align: right;
	font-size: 10px;
	width: 64px;
	height: 64px;
	background-size: cover;
	display: inline-block;
	image-rendering: pixelated;
}
.item_box {
	margin: 4px;
	padding: 48px 6px 0px 0px;
	color: #FFF;
	text-align: right;
	font-size: 10px;
	width: 128px;
	height: 128px;
	background-size: cover;
	display: inline-block;
	image-rendering: pixelated;
}
.item_box:hover {
	background-size: cover;
	display: inline-block;
	image-rendering: pixelated;
	background-color: white;
	cursor: pointer;
}
.large_item_box {

	margin: 4px;
	padding: 48px 6px 0px 0px;
	color: #FFF;
	text-align: right;
	
	width: 128px;
	height: 128px;
	background-size: cover;
	display: inline-block;
	image-rendering: pixelated;
}
.item_inspector {
	margin: 40px auto;
	font-size: 20px;
	width: 300px;
}
.item_cont {
	width: 72px;
	height: 140px;
	line-height: 1.0em;
	display: inline-block;
	vertical-align: top;
	padding: 0px 4px;
}
.currency_holder {
	font-size: 18px;
	margin: 20px 0px;
}
.currency_icon {
	width: 32px;
	height: 32px;
	background-size: contain;
	display: inline-block;
	vertical-align: middle;

}
.combo {
	width: 100%;


}
.combo_left {
	width: 100px;
	height:  140px;
	display: inline-block;
	vertical-align: top;
	margin-left: 20px;
}
.combo_right {
	width: calc(100% - 120px);
	display: inline-block;
	vertical-align: top;
	text-align: right;
	font-size: 10px;

}
.buff_bar_container_left {
	text-align: left;
	z-index: 990;
    position: absolute;
    margin-top: 44px;
    margin-left: 0px;
}
.buff_bar_container_right {
	text-align: right;
	z-index: 990;
    position: absolute;
    margin-top: 44px;
    margin-left: 0px;
}

.notificationsOverlay {
	position: absolute;
	right: 0px;
	top: 0px;
	color: #FFF;
	
	width: 400px;
	margin: 10px;
}
.noteBg {
	background-color: #1c1c1c;
	margin-bottom: 10px;
	cursor: pointer;
}
.noteLeft {
	vertical-align: top;
	display: inline-block;
	width: 100px;
}
.noteRight {
	vertical-align: top;
	display: inline-block;
	width: calc(400px - 100px);
	text-align: left;
	padding: 10px;
}
.noteHeadline {
	font-weight: bold;
	font-size: 18px;
}
.noteMessage {
	font-size: 14px;
}
:root {
	--danger: #B50E0E;
}